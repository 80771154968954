import { useEffect, useState } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons'

import preise from 'assets/downloads/Preisliste2021.pdf'
import { Link } from 'react-router-dom'

function Behandlungskosten() {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  const [pdfWidth, setPdfWidth] = useState(650)
  useEffect(() => {
    if (screen.width < 500 || document.body.clientWidth < 500)
      setPdfWidth(document.body.clientWidth * 0.95)
    else if (screen.width < 768 || document.body.clientWidth < 768)
      setPdfWidth(document.body.clientWidth * 0.9)
  })

  const renderLoading = () => {
    return (
      <div>
        <FontAwesomeIcon
          title="Download"
          icon={faSpinner}
          className="text-primary-dark animate-spin"
          size="6x"
        ></FontAwesomeIcon>
      </div>
    )
  }

  return (
    <div className="pt-20 pb-10 sm:pb-16 px-5 w-full sm:w-2/3 ipad:w-1/2 sm:px-0 max-w-3xl mx-auto">
      <h1 className="font-bold text-3xl sm:text-4xl mt-10 text-center sm:text-left ">
        Behandlungskosten
      </h1>
      <div className="mt-6 text-center sm:text-left">
        <p>
          Die gesetzlichen Krankenkassen gewähren für Heilmassage, Lymphdrainage
          und physikalische Anwendungen einen Kostenersatz in der Höhe von ca.
          80% des Kassentarifes. Voraussetzung ist die ärzliche Verordnung und
          deren chefärzliche Bewilligung.
        </p>
        <br />
        <p>
          Je nach Vertrag beteiligen sich Privatversicherer an Behandlungskosten
          - Nachfragen lohnt sich!
        </p>
      </div>
      <div className="w-full mt-14 relative flex justify-center">
        <div
          className="absolute z-10 -top-8 flex justify-end"
          style={{ width: `${pdfWidth}px` }}
        >
          <a
            href={`downloads/Preisliste.pdf`}
            target="_blank"
            download="Preisliste.pdf"
            className="mr-4 sm:mr-0"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              title="Download"
              icon={faDownload}
              className="ml-2 sm:ml-0 md:ml-2 cursor-pointer hover:text-primary transition-colors duration-200"
              size="lg"
            ></FontAwesomeIcon>
          </a>
        </div>
        <Document
          file={`downloads/Preisliste.pdf`}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => console.error(error)}
          className="flex flex-col items-center"
          renderMode="svg"
          loading={renderLoading}
        >
          <Page
            className="shadow-symmetric rounded-md overflow-hidden"
            pageNumber={pageNumber}
            rednerMode="svg"
            width={pdfWidth}
            renderTextLayer={false}
          />
        </Document>
      </div>
    </div>
  )
}

export default Behandlungskosten
